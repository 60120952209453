<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input class="w120 mb10 mr10" placeholder="分类名称" v-model="filterName" />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="open">+ 添加分类</el-button>
      </div>
    </div>
    <el-table :data="tableData" v-loading="loading" row-key="goodstypeId" border lazy :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="id" align="center" label="" width="50">
        <template slot-scope="scope" v-if="scope.row.parentId">
          <div class="el-icon-pie-chart mb30"></div>
        </template>
      </el-table-column>
      <el-table-column prop="goodPhoto" align="center" label="图标" width="120px">
        <template slot-scope="scope">
          <el-image v-if="scope.row.typeIcon" style="width: 100px; height: 100px;margin-bottom:-10px"
            :src="scope.row.typeIcon">
          </el-image>
          <p v-else style="width: 100px; height: 100px;line-height: 100px;">
            暂无图片
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="dictLabel" align="center" label="大分类" />
      <el-table-column prop="name" align="center" label="分类名称" />
      <el-table-column prop="goodsNum" align="center" label="商品数量" />
      <el-table-column prop="dealNum" align="center" label="总销量" />
      <el-table-column prop="" align="center" label="排序">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="top-start">
            <el-input v-model="scope.row.sort" placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.row)">编辑</el-button>
          <el-button type="text" v-if="!scope.row.parentId" @click="open(scope.row, 'addChild')">添加子分类</el-button>
          <customPopconfirm class="ma ml10 mb10" confirm-button-text="确定" cancel-button-text="取消"
            @confirm="del(scope.row)" title="确认删除？">
            <el-button type="text" style="color: rgb(245, 108, 108);" slot="reference">删除</el-button>
          </customPopconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="mt10" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="currentPage" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
      :total="total" :pager-count="5" />
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="dialogTitle"
      :visible.sync="dialogVisible" :destroy-on-close="true" @close="closeFun" width="400px">
      <el-form ref="formData" :rules="rules" :model="formData" label-width="80px">
        <el-form-item label="图标" prop="typeIcon">
          <el-upload class="iconUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
            :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
            :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="typeIcon">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="h100p d-flex w200 align-items-center">
            <p>最多只能上传1张，建议上传100x100，格式可为png、jpg或jpeg</p>
          </div>
        </el-form-item>
        <el-form-item label="大分类">
          <el-select v-model="formData.dictValue" class="w100p" clearable placeholder="请选择大分类" style="width: 240px">
            <el-option :label="row.dictLabel" :value="row.dictValue" v-for="(row, key) in maxGoodsTypeData" :key="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级分类" v-if="setFormType == 'child'">
          <el-select v-model="formData.parentId" class="w100p" clearable placeholder="请选择上级分类" style="width: 240px">
            <el-option :label="row.name" :value="row.goodstypeId" v-for="(row, key) in parentGoodsTypeData" :key="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="formData.name" class="w100p"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitType" :loading="loadingBtn">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customPopconfirm from "@/components/common/customPopconfirm";

import {
  selectGoodsTypeInfoPC,
  addGoodsType,
  delGoodsType,
  updateGoodsTypeSort,
  getByDictType,
} from "@/api/coupon.js";

export default {
  name: "productList",
  components: {
    customPopconfirm,
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      dialogTitle: "",
      dialogVisible: false,
      maxGoodsTypeData: [],
      parentGoodsTypeData: [],
      typeIcon: [],
      formData: {
        typeIcon: "",
        name: "",
        dictValue: "",
        parentId: ""
      },
      formType: "",
      setFormType: "",
      rules: {
        typeIcon: {
          required: true,
          message: "请上传分类图标",
        },
        name: {
          required: true,
          message: "请输入分类名称",
          trigger: "blur",
        },
      },
      loadingBtn: false,
    };
  },
  computed: {
    uploadDisabled() {
      return this.formData.typeIcon !== "";
    },
  },
  methods: {
    load(tree, treeNode, resolve) {
      let params = {
        goodstypeId: tree.goodstypeId,
        isPage: false,
        showParent: 0
      };
      selectGoodsTypeInfoPC(params).then(res => {
        if (res.data) {
          resolve(res.data);
        } else {
          resolve([]);
        }
      }).catch(err => {
        resolve([]);
      })
    },
    /**@method 关闭模态框清除图标 */
    closeFun() {
      this.formData.typeIcon = "";
      this.typeIcon = [];
    },
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      //-console.log(file, fileList);
      this.formData.typeIcon = "";
      this.typeIcon = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.formData.typeIcon = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
    },
    //分类弹窗
    open(row, type) {
      // if (this.userInfo.roleId || this.jurisdiction) {
      this.row = row.name;
      //新增
      if (!this.row) {
        this.dialogTitle = "新增分类";
        this.formType = "add"
        this.formData = {
          typeIcon: "",
          name: "",
          dictValue: "",
          parentId: ""
        };
        this.typeIcon = [];
        this.setFormType = "child"
      } if (type == 'addChild') {
        this.dialogTitle = "新增分类";
        this.formType = "add"
        this.formData = {
          typeIcon: "",
          name: "",
          dictValue: "",
          parentId: row.goodstypeId
        };
        this.typeIcon = [];
        this.setFormType = "child"
      } else {
        this.dialogTitle = "编辑分类";
        this.formType = "update"
        this.formData = {
          typeIcon: row.typeIcon ? row.typeIcon : "",
          name: row.name,
          dictValue: row.dictValue,
          parentId: row.parentId,
          goodstypeId: row.goodstypeId,
        };
        if (row.parentId) {
          this.setFormType = "child"
        } else {
          this.setFormType = "parent"
        }
        if (row.typeIcon) {
          this.typeIcon = [{ url: row.typeIcon }]
        }
      }
      this.dialogVisible = true;
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return;
      // }
    },
    submitType() {
      this.$refs.formData.validate((vali) => {
        if (vali) {
          let params = {
            typeIcon: this.formData.typeIcon,
            name: this.formData.name,
            dictValue: this.formData.dictValue,
            parentId: this.formData.parentId,
          }
          if (this.formData.parentId) {
            delete params.dictValue;
          }
          let msg = "保存成功";
          if (this.formType === "update") {
            params.goodstypeId = this.formData.goodstypeId;
            msg = "编辑成功"
          }
          this.loadingBtn = true;
          addGoodsType(params).then((res) => {
            this.loadingBtn = false;
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: msg,
                center: true,
              });
              if (this.formType === "add") {
                this.pageNum = 1;
              }
              this.get();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.message);
            }
          }).catch(err => {
            this.loadingBtn = false;
            if (err.code === 201) {
              this.$message.error(err.message);
            }
          });
        }
      });
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //删除弹窗
    del(row) {
      // if (this.userInfo.roleId || this.jurisdiction) {
      delGoodsType({ goodstypeId: row.goodstypeId })
        .then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
            center: true,
          });
          this.get();
        })
        .catch(() => {
          this.$message({
            message: "操作失败",
            type: "warning",
            center: true,
          });
        });
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return;
      // }
    },

    //获取数据
    async get() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        showParent: 1
      };
      this.loading = true;
      this.tableData=[];
      selectGoodsTypeInfoPC(data) //获取分类数据
        .then((res) => {
          this.loading = false;
          let table = [];
          for (let row of res.data.pageInfo.list) {
            table.push({
              ...row,
              hasChildren: true,
              children:[]
            });
          }
          this.tableData = table;
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getInitData() {
      getByDictType({ dictType: "max_goods_type", status: 0 }).then((res) => {
        if (res.code === 200) {
          this.maxGoodsTypeData = res.data.sysDictDataList;
        }
      });
      let params = {
        isPage: false,
        showParent: 1
      };
      selectGoodsTypeInfoPC(params).then(res => {
        if (res.data) {
          this.parentGoodsTypeData = res.data;
        }
      })
    },
    //搜索
    async filterNames() {
      this.pageNum = 1;
      this.get();
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.get();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.loading = true;
      this.get();
    },
    //全删除
    delAll() {
      this.delGoodsT.map((itme) => {
        delGoodsType({ goodstypeId: itme.goodstypeId });
      });
      if (this.delGoodsT.length > 0) {
        this.$message({
          message: "操作成功",
          type: "success",
          center: true,
        });
      }
      this.delGoodsT = [];
      this.get();
    },
    async saveSort(row) {
      if (row && row.sort != "") {
        //-console.log(row);
        const result = await updateGoodsTypeSort(row);
        //-console.log("result:", result);
        this.$message({
          type: "success",
          message: "保存成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },

  //生命周期
  async created() {
    this.getInitData();
    this.get();
  },
};
</script>

<style lang="scss" scoped></style>
